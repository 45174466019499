import { browserTracingIntegration, captureException, init, setContext, setTag, setUser } from '@sentry/vue'

// eslint-disable-next-line no-undef
const sentryEnabled = VITE_SENTRY_ENABLED?.toLowerCase() === 'true'

function getEnvironment() {
  switch (location.hostname) {
    case 'app.picsellia.com':
      return 'production'
    case 'staging.picsellia.com':
      return 'staging'
    case 'trial.picsellia.com':
      return 'trial'
    default:
      return 'development'
  }
}

export function setSentryUser({ id, email, username }) {
  if (!sentryEnabled)
    return
  setUser({ id, email, username })
}

export function setSentryContext(key, context) {
  if (!sentryEnabled)
    return
  setContext(key, context)
}

export function setSentryTag(key, value) {
  if (!sentryEnabled)
    return
  setTag(key, value)
}

export function sentryCaptureException(error) {
  if (!sentryEnabled)
    return
  captureException(error)
}

export default function useSentry(enabled, app) {
  if (!enabled || !sentryEnabled)
    return

  init({
    app,
    dsn: 'https://a1d79dc1bf934e9695c4d519d572316b@o1044018.ingest.sentry.io/4505466000113664',
    integrations: [new browserTracingIntegration()],
    tracesSampleRate: 0.01,
    environment: getEnvironment(),
    tracePropagationTargets: [
      /^https:\/\/staging.picsellia\.com\//,
      /^https:\/\/trial.picsellia\.com\//,
      /^https:\/\/app.picsellia\.com\//,
    ],
  })
}
