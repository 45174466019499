import posthog from 'posthog-js'

// eslint-disable-next-line no-undef
const apiKey = VITE_POSTHOG_API_KEY
// eslint-disable-next-line no-undef
const host = VITE_POSTHOG_API_URL

export default function usePosthog(enabled) {
  if (!enabled || !apiKey || !host) {
    return
  }
  posthog.init(apiKey, { api_host: host })
}
