import usePosthog from '@/js/usePosthog.js'
import useSentry from '@/js/useSentry.js'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import { Inkline } from '@inkline/inkline'
import { useCookies } from '@vueuse/integrations/useCookies'
import { createApp, h } from 'vue'
import '@/styles/index.css'
import 'iconify-icon'

createInertiaApp({
  progress: {
    color: 'var(--system-picsellia)',
  },
  resolve: (name) => {
    const pages = import.meta.glob('./pages/**/*.vue')
    return pages[`./pages/${name}.vue`]()
  },
  setup({ el, App, props, plugin }) {
    const app = createApp({
      render: () => h(App, props),
    })
    app.use(plugin)
    app.use(Inkline, {
      colorMode: 'light',
      routerComponent: Link,
    })
    const cookies = useCookies(['picsellia-tools'])
    const picselliaTools = cookies?.get('picsellia-tools') ?? ''

    useSentry(picselliaTools.includes('sentry'), app)
    app.mount(el)
    usePosthog(picselliaTools.includes('posthog'))
  },
})
